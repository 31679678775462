import React, { useState, useEffect } from 'react';

function OnlineUsers() {
    const [onlineUsersCount, setOnlineUsersCount] = useState(0);
    const [userList, setUserList] = useState([]);
    const [selectedGroupId, setSelectedGroupId] = useState('');
    const [groupUserCounts, setGroupUserCounts] = useState({});

    useEffect(() => {
        fetchOnlineUsersCount();
        const interval = setInterval(fetchOnlineUsersCount, 5000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const counts = {};
        userList.forEach(user => {
            const groupId = getGroupId(user.userId);
            counts[groupId] = (counts[groupId] || 0) + 1;
        });
        setGroupUserCounts(counts);
    }, [userList]);

    const fetchOnlineUsersCount = () => {
        fetch('https://tracker.cialiscr.com/userCount')
            .then(response => response.json())
            .then(data => {
                setOnlineUsersCount(data.count);
                setUserList(data.userList || []);
            })
            .catch(error => {
                console.error('Error fetching online users count:', error);
            });
    };

    const getGroupId = (userId) => {
        return userId.split('-')[0];
    };

    const handleRadioChange = (event) => {
        setSelectedGroupId(event.target.value);
    };

    const filteredUsers = selectedGroupId
        ? userList.filter((user) => getGroupId(user.userId) === selectedGroupId)
        : userList;

    const totalFilteredUsers = filteredUsers.length;

    const groupFilters = ['sicepat', 'pintu', 'tangga', 'es', 'gelas', 'lampu', 'miki', 'nyai', 'singa'];

    return (
        <div>
            <h1>Online Users</h1>
            <p>Total Online Users: {totalFilteredUsers}</p>

            <table>
                <tbody>
                    <tr>
                        <td>
                            <input type="radio" id="all" name="group" value="" onChange={handleRadioChange} checked={!selectedGroupId} />
                            <label htmlFor="all">All</label>
                        </td>
                        <td></td>
                        <td>({totalFilteredUsers})</td>
                    </tr>
                    {groupFilters.map((filter, index) => (
                        <tr key={index}>
                            <td>
                                <input type="radio" id={`group${index}`} name="group" value={filter} onChange={handleRadioChange} checked={selectedGroupId === filter} />
                                <label htmlFor={`group${index}`}>{filter==='singa'?'medali':filter}</label>
                            </td>
                            <td></td>
                            <td>({groupUserCounts[filter] || 0})</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <table border="1">
                <thead>
                    <tr>
                        <th>Device ID</th>
                        <th>IP Address</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredUsers.map(user => (
                        <tr key={user.userId}>
                            <td>{user.userId}</td>
                            <td>{user.ip}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default OnlineUsers;
