// import React, { useState } from 'react';

// function LogsViewer() {
//     const [logs, setLogs] = useState([]);
//     const [selectedDate, setSelectedDate] = useState('');
//     const [totalDevices, setTotalDevices] = useState(0);

//     const getLogs = async () => {
//         try {
//             // const response = await fetch(`http://localhost:3000/logsByDate?date=${selectedDate}`);
//             const response = await fetch(`https://tracker.cialiscr.com/logsByDate?date=${selectedDate}`);
//             const data = await response.json();
//             displayLogs(data);
//         } catch (error) {
//             console.error('Error fetching data:', error);
//             document.getElementById('logsTableBody').innerHTML = `<tr><td colspan="2">Error fetching data. Please try again.</td></tr>`;
//         }
//     };

//     const displayLogs = (data) => {
//         setLogs(data.logs || []);
//         setTotalDevices(data.totalDevices || 0);
//     };

//     const viewDetailedLogs = (deviceId, logs) => {
//         const formattedLogs = logs.map(log => {
//             const timestamp = log.datetime;
//             const formattedDateTime = formatTimestamp(timestamp);
//             return `${formattedDateTime}`;
//         });
//         alert(`Detailed logs for ${deviceId}:\n${formattedLogs.join('\n')}`);
//     };

//     const formatTimestamp = (timestamp) => {
//         if (timestamp && typeof timestamp === 'string') {
//             const date = new Date(timestamp);
//             const formattedDateTime = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()} ${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}:${('0' + date.getSeconds()).slice(-2)}`;
//             return formattedDateTime;
//         } else {
//             return timestamp;
//         }
//     };

//     return (
//         <div>
//             <h1>Logs Viewer</h1>
//             <label htmlFor="datepicker">Select Date:</label>
//             <input type="date" id="datepicker" value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)} />
//             <button onClick={getLogs}>Get Logs</button>
//             <h2>Logs for the selected date:</h2>
//             <div className="logsummary">Total Devices: {totalDevices}</div>
//             <table border="1">
//                 <thead>
//                     <tr>
//                         <th>Device ID</th>
//                         <th>Log Count</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {Object.entries(logs).map(([deviceId, log]) => (
//                         <tr key={deviceId} onClick={() => viewDetailedLogs(deviceId, log)}>
//                             <td>{deviceId}</td>
//                             <td>{log.length}</td>
//                         </tr>
//                     ))}
//                 </tbody>
//             </table>
//         </div>
//     );
// }

// export default LogsViewer;


import React, { useState, useEffect } from 'react';

function LogsViewer() {
    const [logs, setLogs] = useState([]);
    const [selectedDate, setSelectedDate] = useState('');
    const [totalDevices, setTotalDevices] = useState(0);
    const [selectedGroupId, setSelectedGroupId] = useState('');

    useEffect(() => {
        const filteredLogs = Object.entries(logs).filter(([deviceId]) => {
            if (!selectedGroupId) return true;
            const groupId = deviceId.split('-')[0];
            return groupId === selectedGroupId;
        });
        const uniqueDevices = new Set(filteredLogs.map(([deviceId]) => deviceId));
        setTotalDevices(uniqueDevices.size);
    }, [selectedGroupId, logs]);

    const getLogs = async () => {
        try {
            const response = await fetch(`https://tracker.cialiscr.com/logsByDate?date=${selectedDate}`);
            const data = await response.json();
            setLogs(data.logs || []);
        } catch (error) {
            console.error('Error fetching data:', error);
            document.getElementById('logsTableBody').innerHTML = `<tr><td colspan="2">Error fetching data. Please try again.</td></tr>`;
        }
    };

    const handleRadioChange = (e) => {
        setSelectedGroupId(e.target.value);
    };

    const groupFilters = ['sicepat', 'pintu', 'tangga', 'es', 'gelas', 'lampu', 'miki', 'nyai', 'singa'];

    return (
        <div>
            <h1>Logs Viewer</h1>
            <label htmlFor="datepicker">Select Date:</label>
            <input type="date" id="datepicker" value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)} />
            <button onClick={getLogs}>Get Logs</button>
            <h2>Logs for the selected date:</h2>
            <div className="logsummary">Total Devices: {totalDevices}</div>
            <div>
                <input type="radio" id="all" name="group" value="" onChange={handleRadioChange} checked={!selectedGroupId} />
                <label htmlFor="all">All</label>
            </div>
            {groupFilters.map((filter, index) => (
                <div key={index}>
                    <input type="radio" id={`group${index}`} name="group" value={filter} onChange={handleRadioChange} checked={selectedGroupId === filter} />
                    <label htmlFor={`group${index}`}>{filter==='singa'?'medali':filter}</label>
                </div>
            ))}
            <table border="1">
                <thead>
                    <tr>
                        <th>Device ID</th>
                        <th>Log Count</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(logs)
                        .filter(([deviceId]) => {
                            if (!selectedGroupId) return true;
                            const groupId = deviceId.split('-')[0];
                            return groupId === selectedGroupId;
                        })
                        .map(([deviceId, log]) => (
                            <tr key={deviceId}>
                                <td>{deviceId}</td>
                                <td>{log.length}</td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>
    );
}

export default LogsViewer;

